export const setCurrentProduct = product => ({
  type: "CURRENT_PRODUCTS",
  product,
})

export const setCurrentVariant = variant => ({
  type: "CURRENT_VARIANT",
  variant,
})

export const setPreorder = preorder => ({
  type: "SET_PREORDER",
  preorder,
})

export const productPageReset = () => ({
  type: "PRODUCT_PAGE_RESET",
})

export const setCakeMessage = message => ({
  type: "PRODUCT_PAGE_CAKE_MESSAGE",
  message,
})
